import React, { useState, useEffect } from "react";
import styled from "styled-components";
//import styledPdf from "@react-pdf/styled-components";
//import { PDFViewer, Font } from "@react-pdf/renderer";

/* import proximaNovaRegular from "@common/website/assets/fonts/ProximaNova-Regular.ttf";
import domaineDisplayRegular from "@common/website/assets/fonts/DomaineDisplay-Regular.ttf"; */

import toppImage from "@common/website/assets/images/salgsoppgave_topp.jpg";
import testImage from "@common/website/assets/images/salgsoppgave_testbilde.jpg";

/* Font.register({
	family: "DomaineDisplay",
	src: domaineDisplayRegular
});

Font.register({
	family: "ProximaNova",
	src: proximaNovaRegular
}); */

/* Font.registerHyphenationCallback(words => (
  words.map(word => [word])
)); */

export const Property = () => {
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (!mounted) setMounted(true);
	}, []);

	if (mounted) {
		return (
			<Container>
				{/* <PDFViewer>
					<Document>
						<FrontPage size="A4">
							<Square />
							<SquareContent>
								<Text>TOKERUDVEIEN 3</Text>
								<Text>STRØMMEN</Text>
							</SquareContent>
						</FrontPage>
						<ImagePage>
							<Image src={toppImage} />
						</ImagePage>
						<Page size="A4">
							<View>
								<Title>Innhold</Title>
							</View>
							<TocContainer>
								<TocRow>
									<TocItem>
										<Title>06</Title>
										<TocText>{`Informasjon \n om eiendommen`}</TocText>
										<TocSeparator />
									</TocItem>
									<TocItem>
										<Title>12</Title>
										<TocText>Om nabolaget</TocText>
										<TocSeparator />
									</TocItem>
									<TocItem>
										<Title>22</Title>
										<TocText>Plantegning</TocText>
									</TocItem>
								</TocRow>
								<TocRow>
									<TocItem>
										<Title>26</Title>
										<TocText>Viktig informasjon</TocText>
										<TocSeparator />
									</TocItem>
									<TocItem>
										<Title>29</Title>
										<TocText>{`Råd til deg på \n boligjakt`}t</TocText>
									</TocItem>
								</TocRow>
							</TocContainer>
						</Page>
						<ImagePage>
							<Image src={testImage} />
						</ImagePage>
					</Document>
				</PDFViewer> */}
			</Container>
		);
	} else {
		return null;
	}
};

/* const Document = styledPdf.Document`
	max-width: 100%;
	box-sizing: border-box;
	*, *:before, *:after {
	box-sizing: border-box;
	}
`;

const FrontPage = styledPdf.Page`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 100%;
	justify-content: center;
	background-color: ${({ bg }) => (bg ? bg : "#000")};
	color: #fff;
`;

const ImagePage = styledPdf.Page`
	position: relative;
	display: flex;
	flex: 1;
	flex-direction: column;
	max-width: 100%;
	background-color: ${({ bg }) => (bg ? bg : "#000")};
`;

const Image = styledPdf.Image`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
`

const Page = styledPdf.Page`
	position: relative;
	display: flex;
	flex: 1;
	flex-direction: column;
	max-width: 100%;
	margin-top: 96px;
	background-color: ${({ bg }) => (bg ? bg : "#000")};
	color: #fff;
`;

const Square = styledPdf.View`
	position: relative;
	display: flex;
	width: 60%;
	padding-bottom: 60%;
	border: 7px solid #FBDFB8;
`;

const SquareContent = styledPdf.View`
	position: absolute;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

const View = styledPdf.View`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
`;

const Title = styledPdf.Text`
	color: #FBDFB8;
	font-family: "DomaineDisplay";
	font-size: 30px;
`;

const Text = styledPdf.Text`
	font-family: "DomaineDisplay";
	font-size: 30px;
`;

const TocContainer = styledPdf.View`
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	margin-top: 24px;
`;

const TocRow = styledPdf.View`
	display: flex;
	flex-flow: column;
	width: 50%;
`;

const TocItem = styledPdf.View`
	display: flex;
	flex-flow: column;
	align-items: center;
	margin-top: 24px;
`;

const TocSeparator = styledPdf.View`
	display: flex;
	width: 6px;
	height: 2px;
	background: #fff;
	margin-top: 24px;
`;

const TocText = styledPdf.Text`
	display: flex;
	flex-flow: row;
	align-self: flex-start;
	font-size: 14px;
	margin: 12px 0 0 0;
	text-align: center;
	text-transform: uppercase;
	font-family: "DomaineDisplay";
`; */

const Container = styled.div`
	display: flex;
	position: fixed;
	top: 72px;
	left: 0;
	width: 100vw;
	height: calc(100vh - 72px);
	background: #000;

	iframe {
		width: 100vw;
		border: 0;
	}
`;

export default Property;
